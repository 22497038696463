

.topbar{
    display: flex;
    padding: 15px;
    justify-content: right;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 2px;
    width: 120%;
    position: relative;
    left: -22%;
    /* margin-top: 50px; */
  }

  .sidebar-btn{
    /* background-color: #0094fd; */
    border-radius: 5px;
    padding: 6px;
    padding-left: 7px;
    padding-right: 7px;
    height: 30px;
    width: 30px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: 10px;
    margin-right: 25px;
    /* display: none; */
  }
  .account-btn{
    background-color: #23262b;
    border-radius: 25px;
    padding: 11px;
    height: 20px;
    width: 20px;
    margin-right: 25px;
    margin-left: 25px;
  }
  .wallet-main-txt{
    color: #ffffff;
    font-family: Bold;
    font-size: 18px;
    /* margin-right: 10px; */
    /* margin-left: 10px; */
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    /* letter-spacing: 2px; */
  }
  .in-topbar{
    display: flex;
    width: 100%;
  }
  .inn-topbar{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .status-btn{
    border-radius: 15px;
    border: none;
    /* background-color: #00e8dc; */
    background-color: transparent;
    color: #ecc06d;
    font-family: All;
    padding-top: 3px;
    /* margin-left: 6px; */
  }
  .status-txt{
    margin-right: 2px;
    font-size: 12px;
    padding-top: 3px;
    /* font-family: All; */
    color: white;
  }
  .status-div{
    /* margin-left: 15px; */
    padding: 3px;
    padding-left: 8px;
    padding-right: 4px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    border: 0px white solid;
    /* background-color: #e05d5c; */
    width: 300%;
    justify-content: center;
  }
  .sidespace{
    width: 19vw;
  }
  .topbar-header{
    display: flex;
    align-items: center;
    margin-top: -3px;
    /* background-color: rgb(248, 248, 248); */
    border-radius: 10px;
}
.topbar-header img{
    /* width: 35px; */
    width: 100px;
    /* height: 35px; */
    height: 35px;
    margin: 7px;
}
.topbar-header h2{
    color: rgb(231, 231, 231);
    font-family: All;
    font-size: 17px;
    letter-spacing: 4px;
}
  @media screen and (max-width: 600px){
    .sidespace{
      display: none;
    }
    .topbar{
        /* flex-direction: row-reverse; */
        margin-top: 0px;
        width: auto;
        position: static;
    }
    .in-topbar{
        justify-content: right;
    }
    .inn-topbar{
        flex-direction: row-reverse;
    }
    .account-btn{
        margin-right: 10px;
        margin-left: 10px;
    }
    .sidebar-btn{
      display: block;
      margin-right: 10px;
    }
    .topbar-header{
      display: none;
    }
  }