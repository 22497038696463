

.topbar-profile{
    display: flex;
    height: 6.5vh;
    align-items: center;
    background-color: #1e2228;
}
.profile-item-icon{
    color: white;
    font-size: large;
    margin-right: 10px;
    transform: scale(1.0);
    margin-left: 20px;
}
.profile-help-item-icon{
    color: white;
    font-size: large;
    margin-right: 10px;
    transform: scale(0.8);
    margin-right: 20px;
}
.title-profile{
    color: white;
    font-family: Box;
    letter-spacing: 1px;
    font-size: 22px;    
    width: 100%;
    margin-left: 55px;
    margin-top: 29px;
    font-weight: bold;
}
.text-profile{
    color: white;
    font-family: Box;
    letter-spacing: 1px;
    font-size: 19px;    
    /* width: 100%; */
    margin-left: 10px;
    margin-top: 40px;
    font-weight: bold;
}
.userid-profile{
    color: rgb(186, 186, 186);
    font-family: Box;
    letter-spacing: 1px;
    font-size: 11px;    
    margin-left: 10px;
    margin-top: 17px;
    font-weight: bold;
}
.userid-txt-profile{
    color: #0097fe;
    font-family: Box;
    letter-spacing: 1px;
    font-size: 18px;    
    margin-left: 10px;
    margin-top: 15px;
    font-weight: bold;
}
.pspace{
    width: 100%;
}
.profile-page{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.inn-profile{
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile-icon{
    background-color: #30343a;
    border-radius: 55px;
    padding: 25px;
    height: 40px;
    width: 40px;
    margin-right: 25px;
    margin-left: 25px;
}  
.level-profile{
    width: 90%;
    background-color: #30343a;
    /* height: 10vh; */
    border-radius: 15px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}
.levelA{
    display: flex;
    margin-top: 15px;
    margin-left: 20px;
    align-items: center;
    padding-right: 18px;
}
.levelA img{
    height: 18px;
    width: 18px;
}
.level-title{
    font-family: Box;
    font-size: 15px;
    color: white;
    font-weight: bold;
    margin-left: 15px;
    width: 40%;
}
.time-level{
    font-family: Box;
    font-size: 13px;
    color: rgb(168, 168, 168);
    font-weight: bold;
    margin-left: 15px;
    /* width: 30%; */
}
.ppspace{
    width: 80%;
}
.levelB{
    display: flex;
    justify-content: space-between;
    padding: 18px;
}
.levelD{
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
}
.levelB span{
    font-family: Box;
    font-size: 14px;
    color: #0097fe;
    font-weight: bold;
}
.levelD span{
    font-family: Box;
    font-size: 12px;
    color: #0097fe;
    font-weight: bold;
}
.levelC{
    height: 5px;
    background-color: #3e434a;
    border-radius: 10px;
    /* width: 100%; */
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 6px;
    overflow: hidden;
}
.levelC div{
    height: 5px;
    width: 24%;
    background-color: white;
    border-radius: 10px;
}
.statusq{
    color: #555b63;
    font-family: Box;
    width: 80%;
    text-align: end;
    margin-top: 15px;
    font-size: 13px;
}
.profile-opt{
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 4%;
    margin-top: 25px;
}
.opt-line{
    width: 90%;
    height: 1px;
    background-color: #3d4249;
    margin-left: 5%;
    margin-right: 4%;
    margin-top: 10px;
}
.opt-title{
    color: #9d9d9d;
    font-family: All;
}
.opt-subtitle{
    color: #eaeaea;
    font-size: 17px;
    font-family: All;
}
.opt-pass{
    color: #eaeaea;
    font-size: 17px;
    font-family: All;
    background-color: transparent;
    border: none;
}
.optdivbtn{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 35px;
}
.optdivbtn button{
    background-color: rgb(169, 0, 0);
    color: white;
    font-family: Box;
    border-radius: 25px;
    font-size: 16px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border: solid 0px white;
}
.kyc-div{
    border: #ffcc00 1px solid;
    width: 90%;
    /* background-color: #121212; */
    border-radius: 10px;
    /* margin: 20px; */
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    height: 2.5rem;
    align-items: center;
}
.kyc-div img{
    height: 30px;
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
}
.kyc-div span{
    font-family: All;
    color: #ffcc00;
    font-size: 12px;
    white-space: nowrap;
}
.kyc-div button{
    background-color: #ffcc00;
    color: black;
    font-family: All;
    font-size: 12px;
    border: none;
    border-radius: 6px;
    margin-right: 6px;
    white-space: nowrap;
    padding: 6px;
}


@media screen and (max-width: 600px){
    .opt-title{
        font-size: 14px;
    }
    .opt-subtitle{
        font-size: 15px;

    }
    .optdivbtn button{
        padding-top: 8px;
        padding-bottom: 8px;
    }
    
}