.App {
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    height: 100vh;
    background-color: #1e2228;
    color: white;
    flex-direction: column;
  }
  .App h2{
    font-family: Bold;
    font-size: 32px;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  .App h6{
    font-family: Box;
    font-size: 16px;
    text-align: left;
    margin-left: 20px;
    font-weight: lighter;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .App span{
    font-family: Box;
    font-size: 12px;
    text-align: left;
    margin-left: 20px;
    font-weight: lighter;
    margin-top: 0px;
    color: #9e9e9e;
  }
  .form-group span{
    font-family: Box;
    font-size: 12px;
    text-align: left;
    margin-left: 0px;
    font-weight: lighter;
    margin-top: 0px;
    color: #b5b5b5;
    width: 100%;
  }
  .kyc-form {
    /* background: #2c3137; */
    padding: 20px;
    border-radius: 10px;
    /* width: 400px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .kyc-form h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
    background: #2c3137;
    padding: 15px;
    border-radius: 10px;
    border: 1.5px #9e9e9e solid;
    border-style: dashed;
    width: 30%;
    margin-left: 10px;
  }
  .form-group img{
    height: 40px;
    width: 40px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-family: Box;
    font-weight: bold;
    margin-top: 5px;
  }
  
  .form-group input[type="file"] {
    width: 60%;
    border: 2px #007acc solid;
    border-radius: 20px;
    margin-left: 17%;
    margin-right: 20%;
    margin-top: 10px;
    padding: 10px;
    font-family: All;
  }
  .form-group input::file-selector-button{
    background-color: #007acc;
    color: white;
    border: 0;
    padding: 5px;
    border-radius: 20px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 10px;
    font-family: All;

  }
  
  .submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #0097fe;
    border: none;
    border-radius: 25px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    font-family: All;
  }
  
  .submit-btn:hover {
    background-color: #007acc;
  }
  .form-main{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 600px){
.form-group{
  width: 88%;
}
  }
  