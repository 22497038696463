body {
    font-family: Arial, sans-serif;
}

.faq-container {
    /* width: 80%; */
    /* max-width: 800px; */
    /* margin: 0 auto; */
    padding: 20px;
    background-color: #0a101f;
    padding-top: 5%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
}

.faq-subtitle {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2em;
    font-family: All;
    font-size: 13.5px;
    color: #dadada;
    font-weight: lighter;
}
.faq-title {
    text-align: center;
    margin-bottom: 5px;
    font-size: 2em;
    font-family: Box;
    font-size: 42px;
    color: #0fafda;
}

.faq-list {
    /* border: 1px solid #0f2838; */
    border-radius: 5px;
    overflow: hidden;
    width: 80%;
}

.faq-item {
    border: 1px solid #0f2838;
    background-color: #101b2dc0;
    padding: 20px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin: 10px;
    border-radius: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
}

.faq-item:last-child {
    border-bottom: none;
}

.faq-item.active {
    background-image: linear-gradient(to right, #103b53 ,#0c2437, #0c2437);
}

.faq-question {
    font-weight: bold;
    font-size: 1.2em;
    color: white;
    font-family: Box;
    font-size: 15px;
    font-weight: 500;
}
.mainfaq{
    display: flex;
}
.expand-faq{
    background-color: #0e2437;
    padding: 6px;
    border-radius: 15px;
    color: #0fafda;
    padding-bottom: 2px;
    border: solid 1px #1e364a;
    margin: -10px;
    margin-right: 0px;
}

.faq-space{
    /* width:100%; */
    flex-grow: 1;
}
.faq-answer {
    margin-top: 15px;
    display: none;
    transition: max-height 0.5s ease;
    color: rgb(209, 209, 209);
    font-family: Box;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    white-space: pre-wrap;
    word-spacing: 1px;
}

.faq-item.active .faq-answer {
    display: block;
    max-height: 500px;
}






@media screen and (max-width: 600px){
    .faq-container{
        width: 100%;
        padding: 0px;
        padding-top: 15%;
    }
    .faq-list{
        width: 95%;
    }
    .faq-question{
        font-size: 14px;
    }
    .faq-answer{
        font-size: 12px;
    }
    .expand-faq{
        height: 3.5 vh;
    }

}