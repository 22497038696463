.timeline{
    height: 3.2rem;
    /* background-color: #00e8dc; */
    margin-top: 0px;
    margin-bottom: 5px;
    border-radius: 20px;
    margin-right: 20px;
    margin-left: 0px;
    display: flex;
    padding: 12px;
    padding-top: 5px;
    padding-bottom: 15px;
    /* box-shadow: 10px 15px 7px #000000, 0px 0px 6px #969696; */
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    width: 100vw;
    position: relative;
    left: -22%;

}
.line-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.line-item span{
    font-family: Bold;
    font-size: 8.5px;
    /* font-size: 12px; */
    margin-bottom: 20px;
    color: rgb(149, 149, 149);
}
.line-item div{
    /* width: 20px; */
    width: 10px;
    /* height: 20px; */
    height: 10px;
    border-radius: 20px;
    box-shadow: 2px 4px 5px #2a2a2a, 0px 0px 6px #363636;
}
.div-red{
    background-color: rgb(255, 32, 32);
    z-index: 1;
}
.div-green{
    background-color: rgb(0, 202, 0);
    z-index: 1;

}
.div-grey{
    background-color: rgb(54, 54, 54);
    z-index: 1;

}
.tracker{
    background-color: #0094fd;
    /* z-index: 1; */
    position: relative;
    top: 11.5%;
    /* left: 20%; */
    width: 8px;
    height: 8px;
    border-radius: 20px;
    box-shadow: 2px 4px 5px #043647, 0px 0px 6px #088efc;
    display: none;
    /* left: 90%; */

}
.lineline{
    height: 2px;
    /* width: 20px; */
    /* width: 20px; */
    background-color: rgb(70, 70, 70);
    margin-top: 32px;
    /* flex-grow: 1; */
    width: 5.8rem;
    /* overflow: hidden; */
    margin-left: -15px;
    margin-right: -13px;
    /* justify-content: center; */

}
.inline{
    height: 2px;
    /* width: 20px; */
    /* width: 20px; */
    background-color: rgb(70, 70, 70);
    display: flex;
    justify-content: flex-end;
    align-items: center;

}
.linelinex{
    height: 1.5px;
    /* width: 20px; */
    /* width: 20px; */
    /* background-color: rgb(70, 70, 70); */
    margin-top: 41px;
    flex-grow: 1;
}



@media screen and (max-width: 600px){

.lineline{
    width: 60px;
}
.linelinex{
    display: none;
}
.timeline{
    position: static;
}

}