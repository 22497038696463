.tut-div img{
    width: 50%;
    height: 25%;
}
.tut-div{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 10%; */
}
.tut-head{
    color: white;
    font-size: 26px;
    font-family: Bold;
    width: 50%;
    margin: 25px;
}
.tut-con{
    color: rgb(235, 235, 235);
    font-size: 15px;
    font-family: All;
    width: 50%;
    margin: 25px;
    margin-top: 0px;
    white-space: pre-wrap;
}





@media screen and (max-width: 600px){

    .tut-div img{
        width: 80%;
    }
    .tut-head{
    font-size: 20px;
    width: 80%;
    }
    .tut-con{
        font-size: 13px;
        width: 80%;
    }
}