.contact-us-container {
    /* background-color: #1e2228; */
    color: white;
    /* padding: 40px; */
    border-radius: 10px;
    /* max-width: 600px; */
    width: 75vw;
    margin: auto;
    margin-top: 70px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
    height: 80%;
  }
  
  .contact-us-container h2 {
    text-align: center;
    font-family: Bold;
    font-size: 45px;
    width: 100%;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .contact-us-container h4 {
    text-align: center;
    font-family: All;
    font-weight: 100;
    /* margin-bottom: 20px; */
    font-size: 14px;
    /* width: 100%; */
    text-align: left;
    margin-left: 10px;
  }
  
  .contact-us-container form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-us-container label {
    margin-bottom: 15px;
    font-family: All;
  }
  
  .contact-us-container input,
  .contact-us-container select,
  .contact-us-container textarea {
    width: 95%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #0097fe;
    border-radius: 5px;
    background-color: #1e2228;
    color: white;
  }
  .contact-us-container select{
    width: 100%;
  }
  
  .contact-us-container button {
    background-color: #0097fe;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 10px;
    font-family: Bold;
    font-size: 16px
  }
  
  .contact-us-container button:hover {
    background-color: #007acc;
  }
  .topiconcontact{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-top: -20px;
    margin-bottom: 50px;
  }
  .topiconcontact img{
    margin-top: 0vh;
  }