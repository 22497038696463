
.leaderboard h1{
    color: aliceblue;
    font-family: Bold;
    font-size: 22px;
    margin-left: 5px;
}
.leaderboard-list{
    background-color: transparent;
    border: 2px #0094fd solid;
    border-radius: 6px;
    margin: 10px;
    margin-right: 15px;
    overflow: hidden;
    display: flex;
    height: 8vh;
    align-items: center;

}
.leader-rank{
    border-radius: 10px;
    border: 2px #0094fd solid;
    /* padding: 8px; */
    color: aliceblue;
    font-family: All;
    height: 45%;
    padding: 5px;
    width: 30px;
    margin-left: 10px;
    text-align: center;
    font-size: 20px;
}
.rank-title{
    color: aliceblue;
    margin-left: 20px;
    flex-grow: 1;
}
.rank-title h5{
    margin: 0;
    color: #c5bcbc;
    font-size: 12px;
}
.rank-title span{
    font-family: Syc;
    font-size: 15px;
}
.leaderboard-list h3{
    margin-right: 20px;
    color: #fff67f;
    font-family: Bold;
    font-size: 16px;
}

@media screen and (max-width: 600px) {

    .leaderboard-list{
        height: 6vh;
    }
}