html, body {
  /* min-height: 100%; */
  /* max-width: 100%; */
  background-image: linear-gradient(to bottom right, #afccfc, #d0efff);
  height: 100%;
  overflow: hidden;
  /* overflow-x: hidden; */
}
.bg-color{
  background-image: linear-gradient(to bottom right, #afccfc, #d0efff);
}
.reports-scroll {
  overflow-y: auto;
  overscroll-behavior-y: auto;
}
body {
  position: relative
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.trading-layout{
  display: flex;
  /* background-color: #131c21; */
  background-color: #0e0f13;
  /* overflow-y: scroll; */
  /* background-image: linear-gradient(to bottom left, #222a32, #131c21); */
  
}
.in-trading-layout{
  width: 82%;
}
.flow{
  position: absolute;
  width: 130%;
  /* height: 200%; */
  /* background-color: rgb(45, 45, 45,0.75); */
  border-radius: 20px;
  background-image: radial-gradient(#fffdcd42,transparent,transparent);
  opacity: 0.2;
  top: -90%;
  left: 35%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@font-face {
  font-family: "All";
  src: local("PaytoneOne-Regular"),
    url("./fonts/ITC.otf") format("truetype");
  font-weight: lighter;

}
@font-face {
  font-family: "Box";
  src: local("PaytoneOne-Regula"),
    url("./fonts/StefansUhrSquare.ttf") format("truetype");
  font-weight: lighter;

}
@font-face {
  font-family: "Bold";
  src: local("PaytoneOne-Regula"),
    url("./fonts/DroidSans-Bold.ttf") format("truetype");
  font-weight: lighter;

}
@font-face {
  font-family: "Robot";
  src: local("PaytoneOne-Regula"),
    url("./fonts/RoundCornerFont.ttf") format("truetype");
  font-weight: lighter;

}
@font-face {
  font-family: "Syc";
  src: local("PaytoneOne-Regula"),
    url("./fonts/oddlini-semibold.ttf") format("truetype");
  font-weight: lighter;

}






/* .prediction{
  height: 10%;
  display: flex;
} */

/* .out-pred{
  border: 1px solid rgb(104, 104, 104);
  border-radius: 15px;
  margin: 10px;
  background-image: url("backgrad2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
} */
/* .bottom-pred{
  display: flex;
  background-image: linear-gradient(to right,rgb(117, 90, 255) ,  rgb(75, 192, 192));
  align-items: center;
  height: 5vh;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
} */
/* .up-down{
  display: flex;
  align-items: center;
  height: 5vh;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  justify-content: space-around;
}
.up-btn{
  font-family: 'All';
  font-size: 14px;
  width: 45%;
  height: 4vh;
  background-color: rgb(0, 192, 0);
  border: 0px;
  color: antiquewhite;
  border-radius: 20px;
  font-weight: bold;

}
.down-btn{
  font-family: 'All';
  font-size: 14px;
  width: 45%;
  height: 4vh;
  background-color: rgb(255, 26, 26);
  border: 0px;
  color: antiquewhite;
  border-radius: 20px;
  font-weight: bold;
} */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.popup button {
  margin-top: 10px;
}



@media screen and (max-width: 600px) {
  .in-trading-layout{
    width: 100%;
  }
  html, body{
    background-image: none;
    background-color: #131c21;
    height: 100%;
    overflow: hidden;
  }
}