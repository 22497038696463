/* html {
    height: 100%;
  } */
  body,html {
    background-color: #0b0b0b;
  }

.login-container{
    background-color: #ededed;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 80vh; */
}
.view-container{
    justify-content: center;
    display: flex;
    align-items: center;
    width: 85%;
    border-radius: 15px;
    
    /* background-color: #0172da; */
}
.login-grad-out{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    width: 21%;
    height: 36.4rem;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;
    margin-right: -10px;
    z-index: 0;
    margin-bottom: 30px;
    transition: transform 0.3s ease;
    animation: slideFromLeft 1s ease forwards;
    transform: translateX(10%);
}
@keyframes slideFromLeft {
    0% {
    transform: translateX(60%);
    }
    100% {
    transform: translateX(0%);
    }
}


.login-grad{
    display: flex;
    flex-direction: column;
    width: 21vw;
    height: 36.4rem;
    background-image: url("gradbacklogin.jpg");
    background-repeat: no-repeat;
    background-size: 20vw 36.4rem;
    /* background-image: linear-gradient(to bottom , #373977, #917ab1, #9941cc, #524e87, #9d94b2, #764ea7); */
    /* filter: blur(5px); */
    /* background: hsla(238, 31%, 37%, 1);

background: linear-gradient(270deg, hsla(238, 31%, 37%, 1) 0%, hsla(278, 54%, 55%, 1) 48%, hsla(272, 22%, 67%, 1) 100%);

background: -moz-linear-gradient(270deg, hsla(238, 31%, 37%, 1) 0%, hsla(278, 54%, 55%, 1) 48%, hsla(272, 22%, 67%, 1) 100%);

background: -webkit-linear-gradient(270deg, hsla(238, 31%, 37%, 1) 0%, hsla(278, 54%, 55%, 1) 48%, hsla(272, 22%, 67%, 1) 100%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#41437C", endColorstr="#9E4FCB", GradientType=1 ); */
}
.login-grad span{
    margin: 15px;
    font-size: 14px;
    color: rgb(225, 225, 225);
    font-family: 'All';
    letter-spacing: 0.03rem;
}
.in-login-grad{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center ;
    justify-content: center;
}
.in-login-grad h2{
    font-family: Box;
    color: white;
    font-size: 25px;
    margin-bottom: 60px;
}
.card-container{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    /* margin-top: 3rem; */
    height: 30.4rem;
    width: 24rem;
    border-radius: 15px;
    background-color: #f6f3fa;
    border-width:2px;
    border-style: solid;
    /* border-top-color: rgb(103, 103, 103); */
    padding: 2.5rem;
    margin-bottom: 30px;
    margin-top: 1rem;
    transition: transform 0.3s ease;
    animation: slideFromRight 1s ease forwards;
    transform: translateX(-10%);
}
@keyframes slideFromRight {
    0% {
    transform: translateX(-60%);
    }
    100% {
    transform: translateX(0%);
    }
}
.app-name{
    color: rgb(25, 25, 25);
    font-size: 35px;
    margin-bottom: 5px;
}
.dont-sub-text{
    color: rgb(65, 65, 65);
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 15px;
}
.signup-text{
    color: rgb(0, 0, 0);
    margin-left: 5px;
    font-size: 15px;
    cursor: pointer;
    font-weight: bold;
    font-family: Bold;
}

.input-div{
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 7px;
    border: #cacaca 1px solid;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    height: 2.5rem;
    align-items: center;
}
.input-img{
    margin-left: 15px;
    margin-right: 15px;
    width: 25px;
    height: 25px;
    display: none;
}
.input-div input{
    background-color: transparent;
    border: none;
    width: 100%;
    color: #414141;
    font-size: 14px;
    font-family: All;
    height: 1.9rem;
    padding-left: 10px;
}
.input-div select{
    background-color: transparent;
    border: none;
    width: 100%;
    color: #919191;
    font-size: 14px;
    font-family: All;
    border: none;
}
.input-div select option{
    background-color: #121212;
    border: none;
    outline: none;
}
.signup-btn{
    border: #818181 1px solid;
    width: 100%;
    background-color: #121212;
    border-radius: 10px;
    background-color: #0172da;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    height: 2.5rem;
    align-items: center;
    margin-bottom: 0px;
    justify-content: center;
    font-family: All;
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.error-div{
    border: #ee3f4c 1px solid;
    width: 100%;
    /* background-color: #121212; */
    border-radius: 10px;
    /* margin: 20px; */
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    height: 2.5rem;
    align-items: center;
}
.error-div img{
    height: 30px;
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
}
.error-div span{
    font-family: All;
    color: #ee3f4c;
    font-size: 12px;
}
.temp-div{
    background-color: white;
    margin-top: 1rem;
    margin-bottom: 100%;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.temp-div button{
    background-color: #ee3f4c;
    color: white;
    padding: 5px;
    width: 40%;
    height: 2.5rem;
    border-radius: 15px;
    z-index: 999;

}
.move-right {
    /* transform: translateX(172%); */
   
    transform: translateX(0%);
    animation: slideFromMoveRight 1s ease forwards;
  }
  @keyframes slideFromMoveRight {
    0% {
    transform: translateX(0%);
    }
    100% {
    transform: translateX(172%);
    border-radius: 0px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    }
}
  .move-left {
    /* transform: translateX(-55%); */
    transform: translateX(0%);
    animation: slideFromMoveLeft 1s ease forwards;

  }
  @keyframes slideFromMoveLeft {
    0% {
    transform: translateX(0%);
    }
    100% {
    transform: translateX(-55%);
    }
}
  .adjustlogin{
    align-items: flex-end;
  }






  @media screen and (max-width: 600px) {
    /* .login-container{
        height: 70vh;
        max-height: 100%;
    } */
    .login-grad-out{
        width: 100%;
        border-radius: 0px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        margin: 10px;
        height: 11rem;
        transform: translateY(10%);
        background-color: #ee3f4c;
    animation: slideFromLeft 1s ease forwards;
    display: none;
    }
    @keyframes slideFromLeft {
        0% {
        transform: translateY(60%);
        }
        100% {
        transform: translateY(0%);
        }
    }
    .login-grad{
        width: 100%;
    background-size: 100% 100%;
    height: 11rem;
    }
    
    .card-container{
        margin: 25px;
        width: 85%;
        margin-top: -20px;
        padding: 2.0rem;
        animation: slideFromRight 1s ease forwards;
        transform: translateY(-10%);
        margin-bottom: 75%;
    }
    @keyframes slideFromRight {
        0% {
        transform: translateY(-40%);
        }
        100% {
        transform: translateY(10%);
        }
    }
    .view-container{
        flex-direction: column;
    }
    .move-right {
        /* transform: translateY(232%); */
        transform: translateY(10%);
        animation: slideFromMoveRight 1s ease forwards;

        
      }
      @keyframes slideFromMoveRight {
        0% {
        transform: translateY(0%);
        }
        100% {
        transform: translateY(232%);
        border-radius: 0px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        }
    }
      
      .move-left {
        /* transform: translateY(-40%); */
        transform: translateY(10%);
        animation: slideFromMoveLeft 1s ease forwards;
        height: 30%;
      }
      @keyframes slideFromMoveLeft {
        0% {
        transform: translateY(0%);
        }
        100% {
        transform: translateY(15%);
        }
    }
      .adjustlogin{
        align-items: flex-end;
        flex-direction: column-reverse;
        padding-top: 20px;
      }
      body{
  background-image: linear-gradient(to bottom right, #afccfc, #d0efff);
  /* height: 130vh; */

      }
  }
/* html {
    height: 100%;
  }
  body {
    min-height: 100%;
  }

.login-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.view-container{
    display: flex;
    align-items: center;
}
.card-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    height: auto;
    width: 24rem;
    border-radius: 15px;
    background-color: #191919;
    border-width:2px;
    border-style: solid;
    border-top-color: rgb(103, 103, 103);
    padding-top: 0rem;
    padding-bottom: 0rem;
}
.app-name{
    color: white;
    font-size: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.dont-sub-text{
    color: rgb(154, 154, 154);
    font-size: 14px;
}
.signup-text{
    color: rgb(255, 255, 255);
    margin-left: 5px;
    font-size: 15px;
    cursor: pointer;
}
.app-logo{
    width: 70px;
    height: 70px;
}
.input-div{
    width: 90%;
    background-color: #121212;
    border-radius: 10px;
    border: #1e1e1e 2px solid;
    margin: 10px;
    display: flex;
    height: 2.5rem;
    align-items: center;
    margin-bottom: 0px;
}
.input-img{
    margin-left: 15px;
    margin-right: 15px;
    width: 25px;
    height: 25px;
}
.input-div input{
    background-color: transparent;
    border: none;
    width: 100%;
    color: #acacac;
    font-size: 14px;
    font-family: All;
    height: 1.9rem;
}
.input-div select{
    background-color: transparent;
    border: none;
    width: 100%;
    color: #737373;
    font-size: 14px;
    font-family: All;
    border: none;
}
.input-div select option{
    background-color: #121212;
    border: none;
    outline: none;
}
.signup-btn{
    border: #818181 1px solid;
    width: 90%;
    background-color: #121212;
    border-radius: 10px;
    background-color: #0172da;
    margin: 20px;
    display: flex;
    height: 2.5rem;
    align-items: center;
    margin-bottom: 0px;
    justify-content: center;
    font-family: All;
    color: white;
    font-size: 14px;
}
.top-shape{
    height: 0.8rem;
    background-color: #0172da;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-bottom: 1.5rem;

}
.bottom-shape{
    height: 0.8rem;
    background-color: #0172da;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-top: 1.5rem;

}
.error-div{
    border: #ee3f4c 1px solid;
    width: 90%;
    background-color: #121212;
    border-radius: 10px;
    margin: 20px;
    display: flex;
    height: 2.5rem;
    align-items: center;
    margin-bottom: 0px;
}
.error-div img{
    height: 30px;
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
}
.error-div span{
    font-family: All;
    color: #ee3f4c;
    font-size: 12px;
}
.temp-div{
    background-color: white;
    margin-top: 20rem;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.temp-div button{
    background-color: #ee3f4c;
    color: white;
    padding: 5px;
    width: 70%;
    height: 2.5rem;
    border-radius: 15px;

} */