.topbar-withdraw{
    display: flex;
    height: 6.5vh;
    align-items: center;
    background-color: #1e2228;
}
.withdrawal-item-icon{
    color: white;
    font-size: large;
    margin-right: 10px;
    transform: scale(1.0);
    margin-left: 20px;
}
.withdrawal-help-item-icon{
    color: white;
    font-size: large;
    margin-right: 10px;
    transform: scale(0.8);
    margin-right: 20px;
}
.title-withdrawal{
    color: white;
    font-family: Box;
    letter-spacing: 1px;
    margin-left: 20px;
    font-size: 18px;    
    margin-top: 2px;
}
.wspace{
    width: 100%;
}
.with-balance{
    background-color: #1e2228;
    border-radius: 8px;
    width: 92%;
    height: 8vh;
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.add-with{
    background-color: #0097fe;
    border-radius: 8px;
    width: 92%;
    height: 7vh;
    margin-top: 16px;
    display: flex;
    align-items: center;
}
.add-bank-btn-with{
    background-color: #0097fe;
    border-radius: 8px;
    width: 92%;
    height: 6vh;
    margin-top: 16px;
    display: flex;
    align-items: center;
}
.with-page{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.with-bank{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}
.with-bal-img{
    height: 35px;
    width: 35px;
    margin-left: 15px;
}
.in-with-bal{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    width: 100%;
}
.with-txt1-bal{
    color: rgb(226, 226, 226);
    font-family: Box;
    font-size: 15px;
}
.with-txt2-bal{
    color: white;
    font-family: Box;
    font-size: 17px;
    margin-top: 8px;
}
.add-with h4{
    color: white;
    margin-left: 16px;
    font-family: Box;
    font-size: 15px;
    width: 100%;
}
.add-bank-btn-with span{
    color: white;
    margin-left: 16px;
    font-family: Box;
    font-size: 20px;
    width: 100%;
}
.with-bank h4{
    color: white;
    font-family: Box;
    font-size: 24px;
    font-weight: bold;
    width: 90%;
    margin-top: 25px;
}
.bank-txtbox{
    border-radius: 10px;
    border: 2px solid #0097fe;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 85%;
    margin-bottom: 10px;
}
.bank-txt{
    color: rgb(215, 215, 215);
    font-family: Box;
    font-size: 12px;
}
.bank-input{
    background-color: transparent;
    border: none;
    color: white;
    margin-top: 4px;
    box-shadow: none;
    outline: none;
    font-family: Box;
    padding: 5px;
    padding-left: 0px;
    font-size: 15px;
}
.with-history{
    width: 100%;
    /* height: 100vh; */
    /* display: flex; */
    /* flex-direction: column; */
}
.with-history-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.in-with-history h3{
    color: white;
    font-family: Box;
    font-size: 16px;
    margin-bottom: 4px;
}
.in-with-history span{
    color: white;
    font-family: All;
    font-size: 14px;
}
.with-history-line{
    background-color: rgb(136, 136, 136);
    height: 1px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}
.history-txt{
    color: aliceblue;
    width: 90%;
    margin-top: 20px;
    font-size: 24px;
    font-family: Bold;
}