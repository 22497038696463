.prediction{
    height: 10%;
    display: flex;
    /* background-color: #00e8dc; */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    /* border: 1px #00e8dc solid; */
    border-bottom: 0px;
    justify-content: center;

  }
  .time-pred{
        width: 50%;
        /* height: 11vh; */
        text-align: center;
        border-radius: 10px;
        /* margin: 12px; */
        margin-left: 2px;
        margin-right: 4px;
        /* background-color: rgb(52, 53, 55); */
        border: 1px #4a4c54 solid;
        background-color: #15161a;
    box-shadow: 9px 6px 15px #1e1f23, 0px 0px 6px #202020;

  }
  .time-pred h2{
    color: #fbfbfb;
    font-size: 20px;

        font-family: All;
  }
  
  .out-pred{
      /* border: 1px solid rgb(104, 104, 104); */
      border-radius: 15px;
      margin: 10px;
      margin-top: -10px;
      /* background-image: url("backgrad2.jpg"); */
      /* background-position: center;
      background-size: cover;
      background-repeat: no-repeat; */
    }
    .time-down{
      padding: 10px;
      padding-right: 15px;
      padding-left: 12px;
      padding-bottom: 0px;
      display: flex;
      justify-content: space-between;
      /* background-color: rgb(27, 27, 27); */
    height: 4vh;
    /* border-bottom-left-radius: 15px; */
    /* border-bottom-right-radius: 15px; */
    }
    .time-down h5{
      color: rgb(198, 198, 198);
    font-family: All;
    margin: -6px;
    padding-top: 10px;
    font-size: 10.5px;
    }
    .rem-pred{
      width: 50%;
    /* height: 11vh; */
    text-align: center;
    border-radius: 10px;
    margin-right: 2px;
    margin-left: 4px;
    /* margin: 12px; */
    border: 1px #4a4c54 solid;
    background-color: #15161a;
    box-shadow: 9px 6px 15px #1e1f23, 0px 0px 6px #202020;
    /* background-color: rgb(52, 53, 55); */
    }
    .rem-pred h2{
      color: #fdfdfd;
      font-size: 20px;
    font-family: All;
    }
    .rem-down{
      background-color: rgb(27, 27, 27);
    /* height: 4vh; */
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    }
    .rem-down h5{
      color: rgb(198, 198, 198);
    font-family: All;
    margin: -7px;
    padding-top: 10px;
    }
    .bottom-pred{
      display: flex;
      /* background-image: linear-gradient(to right,rgb(117, 90, 255) ,  rgb(75, 192, 192)); */
      background-color: #0094fd;
      align-items: center;
      height: 5vh;
      border-radius: 10px;
      margin-top: 15px;
      /* border-bottom-left-radius: 15px; */
      /* border-bottom-right-radius: 15px; */
    }
    .bottom-pred h4{
      color: black;
      font-family: All;
    }

    .up-down{
      display: flex;
      align-items: center;
      height: 5vh;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      justify-content: space-around;
      /* border: 1px #00e8dc solid; */
      border-top: 0px;
      padding-bottom: 5px;
      margin-top: 15px;
      
    }
    .up-btn{
      display: flex;
      font-family: 'All';
      font-size: 14px;
      width: 48%;
      height: 6vh;
      /* background-color: rgb(0, 192, 0); */
      background-color: #32ba7b;
      border: 1px #32ba7b solid;
      color: #ffffff;
      border-radius: 7px;
      font-weight: bold;
      justify-content: space-between;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 70px;
      /* z-index: 999; */
    
    }

    .down-btn{
      display: flex;
      font-family: 'All';
      font-size: 14px;
      width: 48%;
      height: 6vh;
      background-color: transparent;
      background-color: #e8684e;
      border: 1px #e8684e solid;
      color: #ffffff;
      border-radius: 7px;
      font-weight: bold;
      justify-content: space-between;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 70px;
      /* z-index: 999; */
    }
    .pre-top{
      width: 100%;
      height: 24px;
      margin-bottom: 10px;
    }
    .pre-bot{
      width: 100%;
      height: 24px;
      margin-top: 5px;
    }
    .price-tb-div{
      width: 40%;
      margin-left: 5px;
    }
    .price-tb-divv{
      width: 30%;
      text-align: center;
      border-radius: 10px;
      margin-left: 15px;
      margin-right: 15px;
      border: 1px #4a4c54 solid;
      background-color: #15161a;
  box-shadow: 9px 6px 15px #1e1f23, 0px 0px 6px #202020;
    }
    .price-tb-divv input{
      background-color: transparent;
    color: white;
    font-family: All;
    border: solid 2px #3b3b3b;
    border-radius: 15px;
    padding: 5px;
    margin: 5px;
    padding-left: 10px;
    width: 60%;
    }
    .price-tb-divv span{
      color: #afafaf;
      font-size: 9px;
      padding-left: 2px;
    }