.Sidebar{
    height: 90vh;
    width: 16%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    box-shadow: 15px;
    padding: 10px;
    background-color: #202731;
    margin: 15px;
    transition: transform 0.3s ease;
    z-index: 999;

    position: relative;
    left: -300px;


}
.Sidebar.closed{
    animation: sideBar 0.5s ease forwards;
    /* display: none; */

    /* transform: translateX(-110%); */

}
/* @keyframes sideBar {
    0% {
    transform: translateX(0%);
    opacity: 1;
    }
    100% {
    transform: translateX(-110%);
    opacity: 0;
    position: absolute;

    }
} */
.sidebar-header{
    display: flex;
    align-items: center;
    margin-top: -3px;
    margin-bottom: 50px;
}
.sidebar-header img{
    width: 100px;
    height: 35px;
    margin-right: 18px;
    margin-top: 12px;
}
.sidebar-header h2{
    color: rgb(231, 231, 231);
    font-family: All;
    font-size: 17px;
    letter-spacing: 4px;
}
.sidebar-item{
    display: flex;
    align-items: center;
    margin: 20px;
    justify-content: flex-start;
    width: 70%;
}
.sidebar-item-icon{
    color: white;
    font-size: large;
    margin-right: 10px;
     
}
.sidebar-item span{
    color: white;
    font-size: 16px;
    font-family: All;
    cursor: pointer;
}
.help-option{
    color: white;
    font-size: 15px;
    font-family: All;
    /* cursor: pointer; */
    margin: -10px;
}
.s-item2 .sidebar-item-icon ,.s-item3 .sidebar-item-icon,
.s-item4 .sidebar-item-icon,.s-item4 .sidebar-item-icon,.s-item5 .sidebar-item-icon,.s-item6 .sidebar-item-icon,.s-item7 .sidebar-item-icon {
    color: rgb(155, 155, 155);
}

.s-item2 span,.s-item3 span,.s-item4 span,.s-item5 span,.s-item6 span,.s-item7 span{
    color: rgb(155, 155, 155);
}


.sidebar-item:hover .sidebar-item-icon,.sidebar-item:hover span{
        color: white;
        /* color: #ffffff5d; */
}
.sidespace{
    flex-grow: 1;
}



/* .s-item5{
    margin-bottom: 65%;
} */
@keyframes sideBar {
    0% {
        left: -300px;
    }
    100% {
        left: 0px;
    }
}
@keyframes sideBarClose {
    0% {
        left: 0px;
    }
    100% {
        left: -300px;
    }
}


@media screen and (max-width: 600px) {
    .Sidebar{
        position: absolute;
        width: 50%;
        /* height: 85%; */
        left: -300px;
    animation: sideBarClose 0.5s ease forwards;

    }
    .Sidebar.closed{
    animation: sideBar 0.5s ease forwards;

    }
    @keyframes sideBar {
        0% {
            left: -300px;
        }
        100% {
            left: 0px;
        }
    }
    @keyframes sideBarClose {
        0% {
            left: 0px;
        }
        100% {
            left: -300px;
        }
    }
    /* .s-item5{
        margin-bottom: 100%;
    } */
    
}