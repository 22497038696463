

.navbar{
  background-color: #ededed;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}

.navimg{
    height: 150px;
    width: 150px;
    transform: translateY(10%);
    animation: slideFromBottom 1s ease forwards;
    width: 150px;
    height: 50px;
    margin-left: 50px;
    margin-top: 25px;
    margin-bottom: 25px;
}

@keyframes slideFromBottom {
    0% {
    transform: translateY(60%);
    }
    100% {
    transform: translateY(0%);
    }
}


.nav-btn{
    font-family: All;
    font-size: 16px;
    border-radius: 17px;
    border: none;
    background-color: transparent;
    padding: 7px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 8px;
}
.nav-login-btn{
    font-family: All;
    font-size: 16px;
    border: none;
    background-color: #000000;
    color: white;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 10px;
}
.nav-btn:hover{
background-color: #007aff;
color: white;
}
.nav-login-btn:hover{
background-color: #007aff;
}

  .menu-icon {
    cursor: pointer;
    display: none;
  }
  .menu-img {
    width: 30px; /* Adjust width of the menu icon */
    height: auto;
    cursor: pointer;
  }



@media screen and (max-width: 600px) {

    .sidebar {
        display: block;
        width: 250px;
        height: 100%;
        position: fixed;
        top: 0;
        left: -250px;
        background-color: #f4f4f4;
        z-index: 1000;
        transition: left 0.3s ease;
        background-image: linear-gradient(to bottom right, #afccfc, #d0efff);
    
      }
      .sidebar.open {
        left: 0;
        display: block;
        background-image: linear-gradient(to bottom right, #afccfc, #d0efff);
      }

    .menu-icon {
        display: block;
      }
    
      .navcontents {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .nav-login-btn{
        width: 40%;
      }
    
      .nav-btn {
        margin-bottom: 10px;
      }
    
      .nav-login-btn {
        margin-top: 20px;
      }

}